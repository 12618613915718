.fatOptionSelect__Container {
  @apply grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
}

.fatOptionSelect__Container select {
  @apply border-l-0 rounded-none border-Width__Border__Interactive;
}

.fatOptionSelect__Container select:first-of-type {
  @apply rounded-l-full border-Width__Border__Interactive;
}

.fatOptionSelect__Container select:last-of-type {
  @apply border-l-0 rounded-r-full;
}
