.desktopCarouselImage {
  @apply relative;
}

.desktopCarouselImage:hover::before {
  @apply w-full h-full top-0 left-0 absolute bg-white opacity-50;
  content: "";
}

.desktopCarouselImage:hover::after {
  @apply w-full h-full top-0 left-0 absolute flex justify-center items-center text-5xl;
  content: "🔍";
}
