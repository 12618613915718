.disabledOption,
.disabledColorOption {
  @apply relative cursor-not-allowed opacity-25;
}

.LinearOptionSelector {
  @apply flex flex-row w-full;
}

.LinearOptionSelector select {
  @apply border-l-0;
}

.LinearOptionSelector select:last-child {
  @apply border-l-0 border-r-2 rounded-r-full;
}
