.QuantityField input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.QuantityField input[type="number"]::-webkit-inner-spin-button,
.QuantityField input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.QuantityField {
  @apply flex flex-row h-10 border-2 border-gray-200 rounded-full transition-all;
}

.QuantityField:hover {
  @apply bg-gray-100 border-gray-100;
}

.QuantityField button {
  @apply text-lg rounded-full aspect-square h-full;
}

.QuantityField button:hover {
  @apply bg-white;
}
