@keyframes ctaslide {
  0% {
    opacity: 0;
    transform: translateY(250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ctafadeout {
  0% {
    filter: grayscale(0%);
    opacity: 1;
  }

  100% {
    filter: grayscale(100%);
    opacity: 0.6;
  }
}

/* 767 tailwind breakpoint MD */
@media only screen and (max-width: 767px) {
  .ctaSlideAnimation {
    animation: ctaslide 0.3s ease-out 0s 1 normal forwards;
  }

  .ctaFadeOutAnimation {
    animation: ctafadeout 0.3s ease-out 0s 1 normal forwards;
  }
}

@media only screen and (min-width: 768px) {
  .ctaBottomOverride {
    bottom: unset !important;
  }
}
