.disabledOption {
  @apply relative cursor-not-allowed bg-gray-200 opacity-40;
}

.disabledOption::after {
  @apply absolute z-10 h-0.5 w-1/3 bg-gray-600 border-r-2 top-1/2 opacity-40;
  content: "";
  transform: rotate(45deg);
}

.disabledOption::before {
  @apply absolute z-10 h-0.5 w-1/3 bg-gray-600 border-r-2 top-1/2 opacity-40;
  content: "";
  transform: rotate(-45deg);
}
